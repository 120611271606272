<template>
  <div>
    <permissions-view-body-row
      v-for="(permission, index) in permissions"
      ref="permissions"
      :key="permission.scope"
      :permission-body-class="permissionBodyClass"
      :is-last="index === permissions.length - 1"
      :title="permission.title"
      :hide-collapsed="!hasChildren(permission)"
    >
      <template #checkbox>
        <permissions-view-checkbox-item
          :model-value="form.includes(permission.scope)"
          :title="
            hasChildren(permission) ? $t('pages.permissions.selected') : null
          "
          :readonly="readonly"
          v-bind="getSelectOptions(permission, form)"
          @update:modelValue="toggleAllSectionPermissions(permission, $event)"
        />
      </template>
      <template #body>
        <div
          v-for="(child, subIndex) in permission.children"
          :key="child.scope"
          class="contents"
        >
          <permissions-view-body-multi-row
            v-if="hasChildren(child)"
            v-model="form"
            :child-permission="child"
            :readonly="readonly"
            :is-last="subIndex === permission.children.length - 1"
          />
          <el-col v-else>
            <permissions-view-checkbox-item
              :model-value="form.includes(child.scope)"
              :label="child.description || child.title"
              :readonly="readonly"
              @update:modelValue="
                toggleSingleScope(child.scope, $event, permission)
              "
            />
          </el-col>
        </div>
      </template>
    </permissions-view-body-row>
  </div>
</template>

<script>
import { flatPermissions, getSelectOptions, isAllSelected } from './helpers'
import PermissionsViewBodyRow from './permissions-view-body-row'
import PermissionsViewBodyMultiRow from './permissions-view-body-multi-row'
import PermissionsViewCheckboxItem from './permissions-view-checkbox-item'

export default {
  components: {
    PermissionsViewBodyRow,
    PermissionsViewBodyMultiRow,
    PermissionsViewCheckboxItem
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Array,
      default: () => []
    },
    permissionBodyClass: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      getSelectOptions
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        return this.$emit('update:modelValue', modelValue)
      }
    }
  },
  methods: {
    toggleCollapse(openAll) {
      this.$refs.permissions.forEach((row) => {
        row.toggleCollapse(openAll)
      })
    },
    toggleSingleScope(scope, selected, permission) {
      //remove duplicates and set the permissions
      const scopes = new Set(this.form)
      scopes[selected ? 'add' : 'delete'](scope)

      //toggle parent permissions (top level permission)
      const allSelected = isAllSelected(permission, [...scopes])
      scopes[allSelected ? 'add' : 'delete'](permission.scope)

      //assign back to the date model
      this.form = [...scopes]
    },
    toggleAllSectionPermissions(permission, allSelected) {
      const scopes = flatPermissions(permission).reduce((scopes, key) => {
        scopes[allSelected ? 'add' : 'delete'](key)
        return scopes
      }, new Set(this.form))

      this.form = [...scopes]
    },
    hasChildren(permission) {
      return !!permission?.children?.length
    }
  }
}
</script>
