import baseModel from '@/models/base-model-new'

export default {
  setup(model) {
    const methods = {
      updateMethod() {
        return 'update'
      }
    }
    return baseModel.setup(model, 'userPermissionsTemplates', methods)
  }
}
