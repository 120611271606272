<template>
  <th-page-wrapper
    v-loading="loading"
    color="var(--font-color)"
    :actions="actions"
    class="flex-wrapper leading-normal"
    @save="beforeSave"
    @close="$emit('close')"
  >
    <th-wrapper
      class="h-full flex flex-col"
      header-class="bg-th-light-blue px-3"
      body-class="p-0 overflow-x-auto"
    >
      <!-- Title -->
      <template #title>
        <div
          class="font-semibold mb-2 px-8"
          :class="{ italic: !isWhiteLabel }"
          v-text="subtitle"
        />
      </template>

      <template #header-additional>
        <el-form
          ref="form"
          :model="current"
          :rules="rules"
          class="pt-2 px-8 flex justify-between"
          :class="{
            'pb-8': duplicateNameError
          }"
        >
          <!-- Name -->
          <el-form-item
            prop="name"
            class="m-0"
            :label="$t('pages.permissions.name.label')"
          >
            <el-input
              id="name"
              v-model="current.name"
              data-testid="name"
              class="w-64"
              :disabled="isBasePreset"
              :placeholder="$t('pages.permissions.name.placeholder')"
            />
          </el-form-item>
        </el-form>
        <div
          class="flex justify-between items-center text-th-secondary text-lg font-semibold py-3"
        >
          <!-- Open all -->
          <permissions-view-collapse-item
            v-model="openAll"
            :title="
              openAll
                ? $t('pages.permissions.collapse_all')
                : $t('pages.permissions.open_all')
            "
          />
          <!-- All permissions -->
          <permissions-view-checkbox-item
            :model-value="isAllSelected(permissionsList, current.scopes)"
            v-bind="getSelectOptions(permissionsList, current.scopes)"
            :title="$t('pages.permissions.allPermissions')"
            :readonly="isBasePreset"
            @update:modelValue="toggleAllPermissions"
          />
        </div>
      </template>

      <permissions-view-body
        ref="permissions-view-body"
        v-model="current.scopes"
        :readonly="isBasePreset"
        :permission-body-class="permissionBodyClass"
        :permissions="permissionsList"
      />
    </th-wrapper>
  </th-page-wrapper>
</template>

<script>
import {
  permissionsConfigUser,
  permissionsConfigStaff
} from '@/constants/permissions-constants'
import { mapNames } from '@/utils/permissions'
import {
  getInitialTemplate,
  flatPermissions,
  getSelectOptions,
  isAllSelected,
  TEMP_SCOPE_PREFIX
} from './helpers'
import staffPermissionTemplateModel from './model/staff-permission-template-model'
import userPermissionTemplateModel from './model/user-permission-template-model'
import PermissionsViewBody from './permissions-view-body'
import PermissionsViewCheckboxItem from './permissions-view-checkbox-item'
import PermissionsViewCollapseItem from './permissions-view-collapse-item'
import safeGet from 'just-safe-get'
import { isUnifiedCommerce } from '@/constants'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  components: {
    PermissionsViewBody,
    PermissionsViewCheckboxItem,
    PermissionsViewCollapseItem
  },
  props: {
    templateType: {
      type: String,
      required: true,
      validator: (templateType) => {
        return ['staff', 'user'].includes(templateType)
      }
    },
    templateId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    isBasePreset: {
      type: Boolean,
      default: false
    },
    presetTemplate: {
      type: Object,
      default: null
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['scopes-change'],
  setup(props) {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)

    const model = {
      staff: staffPermissionTemplateModel,
      user: userPermissionTemplateModel
    }[props.templateType]
    return {
      ...model.setup(getInitialTemplate()),
      featureConfig
    }
  },
  data() {
    return {
      openAll: false,
      duplicateNameError: false,
      getSelectOptions,
      isAllSelected,
      rules: {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          { validator: this.validateDuplicateName, trigger: 'change' }
        ]
      },
      translations: {
        reports_financial_accounting: this.$t(
          'nav.main.items.reports_financial_accounting.title'
        ),
        reports_statistics: this.$t('nav.main.items.reports_statistics.title'),
        reports_exports_manager: this.$t(
          'nav.main.items.reports_exports_manager.title'
        ),
        orders: this.$t('nav.main.items.reports_orders.title'),
        inventory: this.$t('nav.main.items.inventory.title'),
        accounting: this.$t('nav.main.items.accounting.title'),
        loyalty: this.$t('nav.main.items.loyalty.title'),
        products: this.$t('nav.main.items.products.title'),
        resources: this.$t('nav.main.items.resources.title'),
        customers: this.$t('nav.main.items.customers.title'),
        suppliers: this.$t('nav.main.items.suppliers.title'),
        documents: this.$t('nav.main.items.documents.title'),
        staff: this.$t('nav.main.items.staff.title'),
        reservations: this.$t('nav.main.items.reservations.title'),
        timetracking: this.$t('nav.main.items.timetracking.title'),
        utilities: this.$t('nav.main.items.utilities.title'),
        settings: this.$t('nav.main.items.settings.title')
      }
    }
  },
  computed: {
    actions() {
      //if isBasePreset (readonly view), show close button instead of save button
      const includeButtons = this.isBasePreset ? ['close'] : []
      const excludeButtons = this.isBasePreset
        ? ['back', 'delete', 'save']
        : ['back', 'delete']

      return { excludeButtons, includeButtons }
    },
    permissionsUser() {
      const permissions = permissionsConfigUser(this)

      return Object.keys(permissions).map((key) => {
        return {
          name: key,
          title: this.translations[key],
          description: key,
          scope: `${TEMP_SCOPE_PREFIX}:${mapNames(key)}`, //top level permissions are temp and not to be send to API
          children: permissions[key]
        }
      })
    },
    permissionsStaff() {
      return permissionsConfigStaff(this)
    },
    permissionsList() {
      return {
        user: this.permissionsUser,
        staff: this.permissionsStaff
      }[this.templateType]
    },
    permissionBodyClass() {
      return {
        user: 'py-0',
        staff: 'py-4'
      }[this.templateType]
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  watch: {
    templateId: {
      immediate: true,
      handler: 'fetchPermissionTemplate'
    },
    'current.name': function () {
      if (this.duplicateNameError) this.duplicateNameError = false
    },
    openAll() {
      this.$refs['permissions-view-body'].toggleCollapse(this.openAll)
    }
  },
  methods: {
    async fetchPermissionTemplate() {
      this.clear()

      // handle custom permissions
      this.id = this.templateId
      await this.fetch()

      // handle preset permissions
      if (this.isBasePreset && this.presetTemplate) {
        this.updateModel(this.presetTemplate)
      }
    },
    toggleAllPermissions(selected) {
      if (selected) {
        this.current.scopes = flatPermissions(this.permissionsList)
      } else {
        this.current.scopes = []
      }
    },
    async beforeSave() {
      await this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave()
        } else {
          return this.$message({
            type: 'warning',
            message: this.$t('common.forms.warning.invalid_input.message')
          })
        }
      })
    },
    checkDuplicateNameError(error) {
      const errorCode = safeGet(error, 'properties.error.response.data.status')
      if (errorCode === 409) {
        this.duplicateNameError = true
        this.$refs.form.validate()
      }
    },
    validateDuplicateName(_, value, callback) {
      // Check if template name is already used (case insensitive and white-space removed)
      const templates =
        this.templateType === 'staff'
          ? this.resources.staffPermissionsTemplates
          : this.resources.userPermissionsTemplates
      const itemsWithSameName = (templates || []).filter(
        (item) => item.name.trim().toLowerCase() === value.trim().toLowerCase()
      )
      if (itemsWithSameName.length) this.duplicateNameError = true
      // In case its an update, the name can be the same
      if (!this.isNew && this.original.name === this.current.name)
        this.duplicateNameError = false
      if (this.duplicateNameError) {
        callback(
          new Error(
            this.$t(
              'pages.settings.users.permissions.templates.duplicate_name.message',
              {
                name: this.current.name
              }
            )
          )
        )
      } else {
        callback()
      }
    },
    async handleSave() {
      const isNew = this.isNew
      const { error } = await this.save()
      if (error) {
        this.checkDuplicateNameError(error)
        //save fail
        const errorMessage = isNew
          ? this.$t(
              'pages.settings.users.permissions.templates.create.errors.post.code_XXX.content'
            )
          : this.$t(
              'pages.settings.users.permissions.templates.edit.form.errors.put.code_XXX.content'
            )

        this.$logException(error, {
          message: errorMessage,
          trackError: false
        })
        return
      }

      //save success
      const successMessageKey = isNew
        ? this.$t(
            'pages.settings.users.permissions.templates.create.messages.save_success'
          )
        : this.$t(
            'pages.settings.users.permissions.templates.edit.messages.update_success'
          )
      this.$message({
        type: 'success',
        message: successMessageKey
      })
      this.$emit('scopes-change', this.current)
    }
  }
}
</script>

<style scoped>
.flex-wrapper :deep(.tillhub-page-wrapper) {
  display: flex;
  height: 100%;
}

.flex-wrapper :deep(.el-main) {
  overflow: visible;
  min-height: 0;
}

.flex-wrapper :deep(.el-header),
.flex-wrapper :deep(.el-footer) {
  height: auto !important;
}

.flex-wrapper :deep(.el-footer .bg-white) {
  background: transparent !important;
}

.flex-wrapper :deep(.el-footer) {
  padding: 2rem 0 0 0;
  border-top: 0 none;
}
.flex-wrapper :deep(.el-footer .px-8) {
  padding: 0 !important;
}
</style>
