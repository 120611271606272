<template>
  <div>
    <el-row class="block">
      <permissions-view-checkbox-item
        v-bind="getSelectOptions(childPermission, form)"
        :label="childPermission.description || childPermission.title"
        :readonly="readonly"
        has-children
        @update:modelValue="
          toggleSubScopes(flatPermissions(childPermission), $event)
        "
      />
      <div
        v-for="(subPermission, index) in childPermission.children"
        :key="subPermission.scope"
      >
        <permissions-view-checkbox-item
          class="pl-4"
          :label="subPermission.description || subPermission.title"
          :model-value="form.includes(subPermission.scope)"
          :readonly="readonly"
          @update:modelValue="
            toggleSubScopes([subPermission.scope], $event, childPermission)
          "
        />
        <hr
          v-if="index === childPermission.children.length - 1 && !isLast"
          class="my-4"
        />
      </div>
    </el-row>
  </div>
</template>

<script>
import { flatPermissions, getSelectOptions, isAllSelected } from './helpers'
import PermissionsViewCheckboxItem from './permissions-view-checkbox-item'

export default {
  components: {
    PermissionsViewCheckboxItem
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    childPermission: {
      type: Object,
      default: () => ({})
    },
    isLast: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { getSelectOptions, flatPermissions }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },
  methods: {
    toggleSubScopes(scopes, isEnabled, parentScope) {
      //creating Set to remove scope duplicate items
      const selectedScopes = new Set(this.form)
      //add or delete items
      scopes.forEach((scope) => {
        selectedScopes[isEnabled ? 'add' : 'delete'](scope)
      })

      //toggle top level permission if all sub permissions are selected
      if (parentScope?.children && parentScope?.scope) {
        const allSubLevelPermissionsSelected = isAllSelected(parentScope, [
          ...selectedScopes
        ])
        selectedScopes[allSubLevelPermissionsSelected ? 'add' : 'delete'](
          parentScope.scope
        )
      }

      //assign scopes back to form
      this.form = [...selectedScopes]
    }
  }
}
</script>
