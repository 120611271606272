<template>
  <!-- Collapse -->
  <el-button text class="focus:outline-none" @click="localValue = !localValue">
    <div class="flex items-center">
      <svgicon
        :src="require('@/assets/icons/th-icon-chevron-down.svg')"
        :style="{ height: '24px', width: '24px' }"
        class="transition-all duration-300 fill-current transform"
        :class="{ '-rotate-180': localValue, '-rotate-90': !localValue }"
      />
      <div v-if="title" class="px-3 flex-1 font-bold text-sm">{{ title }}</div>
    </div>
  </el-button>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  }
}
</script>
