<template>
  <div class="flex items-center h-8">
    <div
      v-if="title"
      class="px-2 text-sm"
      :class="{
        'text-th-secondary': (localValue || indeterminate) && !readonly,
        'text-th-primary-gray': readonly
      }"
    >
      {{ title }}
    </div>
    <el-checkbox
      v-model="localValue"
      :indeterminate="indeterminate"
      data-testid="checkbox"
      :class="{
        'font-semibold': hasChildren
      }"
      :disabled="readonly"
    >
      {{ label }}
    </el-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hasChildren: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        if (!this.readonly) this.$emit('update:modelValue', modelValue)
      }
    }
  }
}
</script>
