<template>
  <div class="hover:bg-th-gray-50">
    <!-- Header -->
    <div
      :class="[
        'flex h-12 px-3 cursor-pointer items-center',
        permissionBodyClass
      ]"
      @click="toggleCollapse(collapsed)"
    >
      <!-- Collapsed Title -->
      <div
        v-if="!hideCollapsed"
        type="text"
        class="flex items-center focus:outline-none flex-1"
      >
        <svgicon
          :src="require('@/assets/icons/th-icon-chevron-down.svg')"
          :style="{ height: '24px', width: '24px' }"
          class="transition-all duration-300 fill-current transform"
          :class="{ '-rotate-180': !collapsed, '-rotate-90': collapsed }"
        />
        <!-- Title -->
        <div class="ml-3 font-semibold" v-text="title" />
      </div>
      <!-- Non Collapsed Title -->
      <div v-else class="flex focus:outline-none flex-1">
        <!-- Title -->
        <div class="ml-8 text-sm" v-text="title" />
      </div>

      <!-- Additional header -->
      <slot name="checkbox" />
    </div>

    <!-- Body -->
    <el-row v-if="!collapsed && !hideCollapsed" class="py-0 pb-4 px-8 block">
      <slot name="body" />
    </el-row>
    <hr v-if="!isLast" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    hideCollapsed: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    permissionBodyClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      collapsed: true
    }
  },
  methods: {
    toggleCollapse(open) {
      this.collapsed = !open
    }
  }
}
</script>
